<template>
  <div>
    <div id="printContent">

      <div class="container-fluid exclude-from-print bg-light">
        <div class="container">
          <div class="row p-3">
            <div class="col-md-8">
              <h3 class="pt-2">Viewing print & PDF ready version</h3>
            </div>
            <div class="col-md-4 text-end">
              <button class="btn btn-primary" v-on:click="print()">Print</button>
            </div>
          </div>
        </div>
      </div>

      <center>  
        <table style="width: 800px;" v-if="listing.template == 1 && !loading">
          <tr>
            <td colspan="2">
              <center><img src="/images/luxury-portfolio.png" style="width: 150px; padding-top: 30px; padding-bottom: 10px;" /></center>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <center>
                <strong style="padding-bottom: 10px;">{{ listing.data.listing[0].loc_suburb }}, {{ listing.data.listing[0].loc_region }}</strong>
                <br/>
                <img :src="listing.featured_image" style="max-height: 400px; padding-bottom: 10px;"/><br/>
                <table style="margin-bottom: 10px;">
                  <tr>
                    <td style="width: 100px;"><center><strong>{{ listing.data.listing[0].land_size }} M2</strong></center></td>
                    <td style="width: 100px;"><center><strong>{{ listing.data.listing[0].lounges }} LOUNGES</strong></center></td>
                    <td style="width: 100px;"><center><strong>{{ listing.data.listing[0].bedrooms }} BEDS</strong></center></td>
                    <td style="width: 100px;"><center><strong>{{ listing.data.listing[0].bathrooms }} BATHS</strong></center></td>
                  </tr>
                </table>
              </center>
              <h1 style="font-size: 28px; margin-top: 20px; margin-bottom: 20px;">R {{ formattedPrice }}</h1>
            </td>
          </tr>
        </table>
        <table style="width: 800px;" v-if="listing.template == 1 && !loading">
          <tr>
            <td>
              <span style="font-size: 13px;"><center><strong>{{ listing.data.listing[0].marketing_heading }}</strong><br/>{{ listing.ai_description }}</center></span>
            </td>
          </tr>
        </table>
        <table v-if="listing.template == 1 && !loading">
          <tr>
            <td style="margin-top: 0px; padding: 10px;">
              <center>
                <br/><br/><img :src="this.agent.agent_photo" style="width: 80px;" /><br/><br/>
                <h5 class="switch-blue mb-3">{{ agent.first_name }} {{ agent.last_name }}</h5>
                <a class="switch-blue" :href="'tel:' + agent.cell_number">{{ agent.cell_number }}</a><br/>
                <a class="switch-blue" :href="'mailto:' + agent.email_address">{{ agent.email_address }}</a>
              </center>
            </td>
            <td style="margin-top: 0px; padding: 10px;" v-if="this.agent_2.agent_photo">
              <center>
                <br/><br/><img :src="this.agent_2.agent_photo" style="width: 80px;" /><br/><br/>
                <h5 class="switch-blue mb-3">{{ agent_2.first_name }} {{ agent_2.last_name }}</h5>
                <a class="switch-blue" :href="'tel:' + agent_2.cell_number">{{ agent_2.cell_number }}</a><br/>
                <a class="switch-blue" :href="'mailto:' + agent_2.email_address">{{ agent_2.email_address }}</a>
              </center>
            </td>
            <td style="margin-top: 0px; padding: 10px;" v-if="this.agent_3.agent_photo">
              <center>
                <br/><br/><img :src="this.agent_3.agent_photo" style="width: 80px;" /><br/><br/>
                <h5 class="switch-blue mb-3">{{ agent_3.first_name }} {{ agent_3.last_name }}</h5>
                <a class="switch-blue" :href="'tel:' + agent_3.cell_number">{{ agent_3.cell_number }}</a><br/>
                <a class="switch-blue" :href="'mailto:' + agent_3.email_address">{{ agent_3.email_address }}</a>
              </center>
            </td>
          </tr>
        </table>
      </center>

    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  components: {
    
  },
  data() {
    return {
      listing: { },
      agent: { },
      agent_2: { },
      agent_3: { },
      loading: false,
      template: 1,
      activeImageIndex: -1,
      isImageExpanded: false,
      isImageClicked: false,
      originalListings: null,
      listings: null,
    };
  },
  created() {
    this.fetchListing()
  },
  mounted() {
    window.addEventListener("load", () => console.log('loaded'))
  },
  computed: {
    formattedPrice() {
      const price = this.listing.data.listing[0].price;
      return price.toLocaleString();
    },
    formattedDescription() {
      const rawDescription = this.listing.ai_long_description;
      const sentences = rawDescription.split('. ');
      const paragraphs = [];
      for (let i = 0; i < sentences.length; i += 3) {
        paragraphs.push(sentences.slice(i, i + 3).join('. '));
      }
      return paragraphs;
    }
  },
  methods: {
    print: async function () {
      window.print()
    },
    fetchListing: async function () {
      this.loading = true
      try {
        const response = await axios.post('https://luxury-dev.chaseveritt.co.za/api/fetch_listing.php', {
          id: this.$route.params.propertyId
        }, {
          headers: {
            'Accept': 'application/json',
          }
        });
        this.listing = response.data.listing
      } catch (error) {
        console.log(error)
      }
      try {
        const agentResponse = await axios.post('https://luxury-dev.chaseveritt.co.za/api/agent.php', {
          id: this.listing.data.listing[0].agent
        }, {
          headers: {
            'Accept': 'application/json',
          }
        })
        this.agent = agentResponse.data.data.agent[0]
      } catch (error) {
        console.log(error)
      }

      if (this.listing.data.listing[0].agent_2 != null) {
        try {
          const agentResponse = await axios.post('https://luxury-dev.chaseveritt.co.za/api/agent.php', {
            id: this.listing.data.listing[0].agent_2
          }, {
            headers: {
              'Accept': 'application/json',
            }
          })
          this.agent_2 = agentResponse.data.data.agent[0]
        } catch (error) {
          console.log(error)
        }
      }

      if (this.listing.data.listing[0].agent_3 != null) {
        try {
          const agentResponse = await axios.post('https://luxury-dev.chaseveritt.co.za/api/agent.php', {
            id: this.listing.data.listing[0].agent_3
          }, {
            headers: {
              'Accept': 'application/json',
            }
          })
          this.agent_3 = agentResponse.data.data.agent[0]
        } catch (error) {
          console.log(error)
        }
      }
      this.listing.template = 1
      this.loading = false
    }
  }
};
</script>

<style scoped>
  .expanded {
    transform: scale(1.2);
    z-index: 1;
  }

  @media print {
    .page-break {
      page-break-before: always;
      page-break-after: always;
    }
    .exclude-from-print {
      display: none;
    }
  }
</style>